import { createActions } from "redux-actions";

export const {
    getExchangeCode,
    signIn,
    autoLogin,
    signOut,
    forgotPassword,
    forgotPasswordSubmit,
    changePassword,
    selectUserRole,
    getAccountData,
    acceptLicense,
    test,
} = createActions(
    {
        GET_EXCHANGE_CODE: {
            START: ({ code }) => ({
                code,
            }),
            SUCCESS: undefined,
            FAILURE: undefined,
        },
        SIGN_IN: {
            START: ({ userName, password, newPassword }) => ({
                userName,
                password,
                newPassword,
            }),
            SUCCESS: ({ isAuthorized, email, accountType }) => ({
                isAuthorized,
                email,
                accountType,
            }),
            FAILURE: ({ isAuthorized }) => ({ isAuthorized }),
        },
        AUTO_LOGIN: {
            START: ({ token }) => ({
                token,
            }),
            SUCCESS: ({ isAuthorized }) => ({
                isAuthorized,
            }),
            FAILURE: ({ isAuthorized }) => ({ isAuthorized }),
        },
        SIGN_OUT: {
            START: undefined,
            SUCCESS: ({ isAuthorized }) => ({ isAuthorized }),
            FAILURE: undefined,
        },
        FORGOT_PASSWORD: {
            START: ({ userName }) => ({ userName }),
            SUCCESS: undefined,
            FAILURE: undefined,
            HANDLE_SERVICE_SUCCESS_RESPONSE: ({ userName }) => ({ userName }),
            HANDLE_SERVICE_ERROR_RESPONSE: ({ error }) => ({ error }),
        },
        FORGOT_PASSWORD_SUBMIT: {
            START: ({ userName, code, newPassword }) => ({ userName, code, newPassword }),
            SUCCESS: undefined,
            FAILURE: undefined,
            HANDLE_SERVICE_SUCCESS_RESPONSE: undefined,
            HANDLE_SERVICE_ERROR_RESPONSE: ({ error }) => ({ error }),
        },
        CHANGE_PASSWORD: {
            START: ({ oldPassword, newPassword }) => ({ oldPassword, newPassword }),
            SUCCESS: undefined,
            FAILURE: undefined,
            HANDLE_SERVICE_SUCCESS_RESPONSE: undefined,
            HANDLE_SERVICE_ERROR_RESPONSE: ({ error }) => ({ error }),
        },
        SELECT_USER_ROLE: ({ userRole }) => ({ userRole }),
        GET_ACCOUNT_DATA: {
            START: undefined,
            SUCCESS: (data) => data,
            FAILURE: undefined,
        },
        ACCEPT_LICENSE: {
            START: undefined,
            SUCCESS: undefined,
            FAILURE: undefined,
        },
        TEST: {
            // TODO: remove
            SIGN_PRIVACY_POLICY: undefined,
        },
    },
    { prefix: "AUTH" }
);
