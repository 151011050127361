import { all, call, put, takeEvery, select, fork } from "redux-saga/effects";

import * as ApiService from "services/api/ApiService";
import NotificationsService from "services/notifications/NotificationsService";
import LocalStorageService from "services/localStorage/LocalStorageService";
import HistoryService from "services/history/HistoryService";
import { setAmplifyConfig } from "services/AWSAmplify/AWSAmplifyConfig";
import { handleRequestError } from "services/api/apiTools";

import AWSAmplifyService from "services/AWSAmplify/AWSAmplifyService";
import NavigationService from "services/navigation/NavigationService";

import { storeItemChangeWatcherSaga } from "helpers/storeItemChangeWatcherSaga";

import { ROUTES } from "constants/routes";
import { USER_ROLES } from "constants/userRoles";
import { CHALLENGES_NAMES } from "constants/amplifyAuthParams";

import * as actions from "./actions";
import * as rootActions from "store/rootActions";
import * as authActions from "store/auth/actions";
import LanguageService from "services/language/LanguageService";
import { mappedLocales } from "сomponents/LanguageSelector/LanguageSelector";
import notificationsService from "services/notifications/NotificationsService";

export function* signInSaga(action) {
    const { userName, password, newPassword } = action.payload;
    let data;

    try {
        data = yield call(AWSAmplifyService.signIn, userName, password, newPassword);
    } catch (errors) {
        notificationsService.throwNotification(
            "error",
            LanguageService.t("UI:Failure"),
            LanguageService.t("Auth:WrongCredentials")
        );
        yield put(actions.signIn.failure({ isAuthorized: false }));
        return;
    }

    const obj = JSON.parse(JSON.stringify(data));

    const incomingPoolId = obj.pool.userPoolId;

    const isNewPasswordOnWrongPageCase =
        data.challengeName &&
        data.challengeName === CHALLENGES_NAMES.newPasswordRequired &&
        (NavigationService.isRoleSelectPage() || NavigationService.isAdminSignInPage());

    if (isNewPasswordOnWrongPageCase) {
        yield put(actions.signIn.failure({ isAuthorized: false }));

        if (AWSAmplifyService.isAdminUserPool(incomingPoolId)) {
            NavigationService.navigateToPath(ROUTES.REGISTRATION_ADMIN.path);
        }

        if (AWSAmplifyService.isDoctorUserPool(incomingPoolId)) {
            NavigationService.navigateToPath(ROUTES.REGISTRATION_DOCTOR.path);
        }

        if (AWSAmplifyService.isPatientUserPool(incomingPoolId)) {
            NavigationService.navigateToPath(ROUTES.REGISTRATION_PATIENT.path);
        }

        NotificationsService.throwNotification(
            "warning",
            LanguageService.t("UI:Warning"),
            LanguageService.t("UI:PasswordFirst")
        );
        return;
    }

    if (!data.signInUserSession) {
        NotificationsService.throwNotification(
            "success",
            LanguageService.t("UI:Success"),
            LanguageService.t("UI:PasswordUpdated")
        );
        yield put(
            actions.signIn.success({
                isAuthorized: false,
                email: "",
            })
        );
        HistoryService.push(`/${ROUTES.ROLE_SELECT.path}`);
        return;
    }

    const email = data.signInUserSession.idToken.payload.email;
    const jwtToken = data.signInUserSession.idToken.jwtToken;

    LocalStorageService.setItem("apiKey", jwtToken);
    ApiService.api.saveToken(jwtToken);

    yield put(
        actions.signIn.success({
            isAuthorized: Boolean(jwtToken),
            accountType: data.role,
            email,
        })
    );

    yield call(getAuthenticatedUserDataAndRedirect);
}

export function* autoLoginSaga(action) {
    const { token } = action.payload;
    let data;

    try {
        ApiService.api.deleteToken();
        data = yield call(ApiService.patientAutoLogin, { token });
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.signIn.failure({ isAuthorized: false }));
        return;
    }

    if (!data.jwt) {
        NotificationsService.throwNotification(
            "error",
            LanguageService.t("UI:Error"),
            LanguageService.t("UI:OopsWrong")
        );
        yield put(
            actions.signIn.success({
                isAuthorized: false,
                email: "",
            })
        );
        HistoryService.push(`/${ROUTES.ROLE_SELECT.path}`);
        return;
    }

    const jwtToken = data.jwt;

    LocalStorageService.setItem("apiKey", jwtToken);
    ApiService.api.saveToken(jwtToken);

    yield put(
        actions.signIn.success({
            isAuthorized: Boolean(jwtToken),
            accountType: USER_ROLES.patient,
        })
    );

    yield call(getAuthenticatedUserDataAndRedirect);
}

export function* getAuthenticatedUserDataAndRedirect() {
    const isLoadingSelector = (state) => state?.auth?.authenticatedUserData?.isLoading;
    const startAction = actions.getAccountData.start;
    const endActions = [actions.getAccountData.success, actions.getAccountData.failure];
    var watcherTask = yield fork(
        storeItemChangeWatcherSaga,
        isLoadingSelector,
        startAction,
        endActions,
        ProcessAccountDataFetchAndNavigateToDefaultSaga,
        watcherTask
    );

    yield put(actions.getAccountData.start());
}

export function* ProcessAccountDataFetchAndNavigateToDefaultSaga(endAction) {
    if (endAction.type === actions.getAccountData.success().type) {
        const userLocale = yield select(
            (state) => state.auth.authenticatedUserData.data?.data?.locale
        );
        const userLocalLanguage = LanguageService.getCurrentLanguage();

        if (userLocale && !userLocale.includes(userLocalLanguage)) {
            ApiService.setUserLocale(mappedLocales[userLocalLanguage]);
        }

        yield NavigationService.navigateToDefaultPath();
    } else {
        NotificationsService.throwNotification(
            "error",
            LanguageService.t("UI:Error"),
            LanguageService.t("UI:SignOut.TryLater")
        );
    }
}

export function* signOutSaga() {
    LocalStorageService.clear("apiKey");
    ApiService.api.deleteToken();

    yield put(rootActions.resetState());

    yield put(actions.signOut.success({ isAuthorized: false }));

    HistoryService.push(`/${ROUTES.ROLE_SELECT.path}`);
}

export function* forgotPasswordSaga(action) {
    const { userName } = action.payload;

    try {
        yield call(AWSAmplifyService.forgotPassword, userName);
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.forgotPassword.failure());
        return;
    }
}

export function* forgotPasswordHandleServiceSuccessResponseSaga(action) {
    const { userName } = action.payload;
    yield NotificationsService.throwNotification(
        "success",
        LanguageService.t("UI:Success"),
        LanguageService.t("UI:VerificationCode.Sent")
    );
    yield NavigationService.navigateToPath(ROUTES.RESET_PASSWORD.path, { userName });
}

export function* forgotPasswordHandleServiceErrorResponseSaga(action) {
    const { error } = action.payload;

    yield NotificationsService.throwNotification(
        "error",
        LanguageService.t("UI:Error"),
        String(error?.message) ?? LanguageService.t("UI:UnknownError")
    );
}

export function* forgotPasswordSubmitSaga(action) {
    const { userName, code, newPassword } = action.payload;

    try {
        yield call(AWSAmplifyService.forgotPasswordSubmit, userName, code, newPassword);
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.forgotPasswordSubmit.failure());
        return;
    }
}

export function* forgotPasswordSubmitHandleServiceSuccessResponseSaga() {
    yield NotificationsService.throwNotification(
        "success",
        LanguageService.t("UI:Success"),
        LanguageService.t("UI:PasswordChanged")
    );
    yield NavigationService.navigateToDefaultPath();
}

export function* forgotPasswordSubmitHandleServiceErrorResponseSaga(action) {
    const { error } = action.payload;

    yield NotificationsService.throwNotification(
        "error",
        LanguageService.t("UI:Error"),
        String(error?.message) ?? LanguageService.t("UI:UnknownError")
    );
}

export function* changePasswordSaga(action) {
    const { oldPassword, newPassword } = action.payload;

    try {
        yield call(AWSAmplifyService.changePassword, oldPassword, newPassword);
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.changePassword.failure());
        return;
    }
}

export function* changePasswordHandleServiceSuccessResponseSaga() {
    yield NotificationsService.throwNotification(
        "success",
        LanguageService.t("UI:Success"),
        LanguageService.t("UI:PasswordChanged")
    );
    yield put(authActions.signOut.start());
}

export function* changePasswordHandleServiceErrorResponseSaga(action) {
    const { error } = action.payload;

    yield NotificationsService.throwNotification(
        "error",
        LanguageService.t("UI:Error"),
        String(error?.message) ?? LanguageService.t("UI:UnknownError")
    );
}

function* selectUserRoleSaga(action) {
    const { userRole } = action.payload;

    yield call(setAmplifyConfig, userRole);
}

export function* getAccountDataSaga() {
    let responseData;

    try {
        responseData = yield call(ApiService.accountDataGet);
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.getAccountData.failure());
        return;
    }

    yield put(actions.getAccountData.success(responseData));

    return responseData;
}

function* acceptLicenseSaga() {
    const accountType = yield select((state) => state.auth.accountType);

    try {
        if (accountType === USER_ROLES.doctor) {
            yield call(ApiService.licenseAcceptDoctor);
        }

        if (accountType === USER_ROLES.patient) {
            yield call(ApiService.licenseAcceptPatient);
        }
    } catch (errors) {
        handleRequestError(errors);
        yield put(actions.acceptLicense.failure());
        return;
    }

    yield put(actions.acceptLicense.success());

    yield call(getAuthenticatedUserDataAndRedirect);
}

function restoreSession() {
    const token = LocalStorageService.getItem("apiKey");
    if (!token) return;
    ApiService.api.saveToken(token);
}

export function* authSagas() {
    yield all([
        yield takeEvery(actions.signIn.start, signInSaga),
        yield takeEvery(actions.autoLogin.start, autoLoginSaga),
        yield takeEvery(actions.signOut.start, signOutSaga),
        yield takeEvery(actions.forgotPassword.start, forgotPasswordSaga),
        yield takeEvery(
            actions.forgotPassword.handleServiceSuccessResponse,
            forgotPasswordHandleServiceSuccessResponseSaga
        ),
        yield takeEvery(
            actions.forgotPassword.handleServiceErrorResponse,
            forgotPasswordHandleServiceErrorResponseSaga
        ),
        yield takeEvery(actions.forgotPasswordSubmit.start, forgotPasswordSubmitSaga),
        yield takeEvery(
            actions.forgotPasswordSubmit.handleServiceSuccessResponse,
            forgotPasswordSubmitHandleServiceSuccessResponseSaga
        ),
        yield takeEvery(
            actions.forgotPasswordSubmit.handleServiceErrorResponse,
            forgotPasswordSubmitHandleServiceErrorResponseSaga
        ),
        yield takeEvery(actions.changePassword.start, changePasswordSaga),
        yield takeEvery(
            actions.changePassword.handleServiceSuccessResponse,
            changePasswordHandleServiceSuccessResponseSaga
        ),
        yield takeEvery(
            actions.changePassword.handleServiceErrorResponse,
            changePasswordHandleServiceErrorResponseSaga
        ),
        yield takeEvery(actions.selectUserRole, selectUserRoleSaga),
        yield takeEvery(actions.getAccountData.start, getAccountDataSaga),
        yield takeEvery(actions.acceptLicense.start, acceptLicenseSaga),
        call(restoreSession),
    ]);
    ApiService.api.saveToken(LocalStorageService.getItem("apiKey"));
}
