import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { PAGE_SIZE } from "constants/pagination";
import { ROUTES } from "constants/routes";

import * as clinicsActions from "store/clinics/actions";

import HistoryService from "services/history/HistoryService";

import ListTable from "componentsShared/ListTable/ListTable";

function ListTableClinics() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const data = useSelector((state) => state?.clinics?.clinicsList?.data) ?? {};
    const dataSequence =
        useSelector((state) => state?.clinics?.clinicsList?.dataSequence) ?? [];
    const totalElements =
        useSelector((state) => state?.clinics?.clinicsList?.meta?.totalElements) ?? 0;

    const [clinicsList, setClinicsList] = useState([]);

    useEffect(() => {
        dispatch(clinicsActions.getClinicsList.start({ pageSize: PAGE_SIZE, pageNumber: 0 }));
    }, []);

    useEffect(() => {
        const clinicsListProcessed = dataSequence.map((key) => {
            const { name, locale, id } = data[key];
            return {
                name,
                locale,
                id,
            };
        });

        setClinicsList(clinicsListProcessed);
    }, [data, dataSequence]);

    const columns = [
        {
            label: t("Clinics:ListTableClinics.table.column.label.clinicName"),
            prop: "name",
            sortable: true,
        },
        {
            label: t("Clinics:ListTableClinics.table.column.label.locale"),
            prop: "locale",
            sortable: true,
        },
        {
            label: t("Clinics:ListTableClinics.table.column.label.clinicId"),
            prop: "id",
            sortable: true,
        },
    ];

    function onTableRowClick(rowData) {
        HistoryService.push(`/${ROUTES.SUPERADMIN_CLINIC_PROFILE.path}/${rowData.id}`);
    }

    const onPaginationChange = (page) => {
        dispatch(
            clinicsActions.getClinicsList.start({ pageSize: PAGE_SIZE, pageNumber: page - 1 })
        );
    };

    return (
        <ListTable
            columns={columns}
            onTableRowClick={onTableRowClick}
            tableData={clinicsList}
            withPagination={true}
            totalElements={totalElements}
            onPaginationChange={onPaginationChange}
            isWithoutBorders
        />
    );
}

export default ListTableClinics;
