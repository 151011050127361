import React from "react";
import { useSelector } from "react-redux";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import { get } from "lodash";

import ProtectedRoute from "./ProtectedRoute";
import HistoryService from "services/history/HistoryService";

import { ROUTES } from "constants/routes";
import { USER_ROLES } from "constants/userRoles";
import { DEFAULT_PATHS_MAP } from "constants/defaultPathsMap";

import AuthLayout from "layouts/topLevelLayouts/AuthLayout/AuthLayout";
import SidebarLeftLayout from "layouts/topLevelLayouts/SidebarLeftLayout/SidebarLeftLayout";
import CleanPageLayout from "layouts/topLevelLayouts/CleanPageLayout/CleanPageLayout";

import PrivacyPolicyPage from "pages/PrivacyPolicyPage/PrivacyPolicyPage";
import CookiesPolicyPage from "pages/CookiesPolicyPage/CookiesPolicyPage";

import RoleSelect from "сomponents/RoleSelect/RoleSelect";

import SignInView from "pages/SignInView/SignInView";
import SignOutView from "pages/SignOutView/SignOutView";

import ForgotPasswordView from "pages/ForgotPasswordView/ForgotPasswordView";
import ResetPasswordView from "pages/ResetPasswordView/ResetPasswordView";
import ChangePasswordView from "pages/ChangePasswordView/ChangePasswordView";

import TermsAndConditionsView from "pages/TermsAndConditionsView/TermsAndConditionsView";
import AuxSurveyView from "pages/AuxSurveyView/AuxSurveyView";

import SuperadminResultsView from "pages/SuperadminResultsView/SuperadminResultsView";
import SuperadminClinicsListView from "pages/SuperadminClinicsListView/SuperadminClinicsListView";
import SuperadminAdminsListView from "pages/SuperadminAdminsListView/SuperadminAdminsListView";
import SuperadminDoctorsListView from "pages/SuperadminDoctorsListView/SuperadminDoctorsListView";
import SuperadminPatientsListView from "pages/SuperadminPatientsListView/SuperadminPatientsListView";

import AdminDoctorsListView from "pages/AdminDoctorsListView/AdminDoctorsListView";
import AdminPatientsListView from "pages/AdminPatientsListView/AdminPatientsListView";
import ProfileClinicView from "pages/ProfileClinicView/ProfileClinicView"; // TODO: rename view component
import ProfileAdminView from "pages/ProfileAdminView/ProfileAdminView"; // TODO: rename view component
import ProfileDoctorView from "pages/ProfileDoctorView/ProfileDoctorView"; // TODO: rename view component
import ProfilePatientView from "pages/ProfilePatientView/ProfilePatientView"; // TODO: rename view component

import DoctorPatientsListView from "pages/DoctorPatientsListView/DoctorPatientsListView";
import DoctorResourcesView from "pages/DoctorResourcesView/DoctorResourcesView";
import DoctorResourcesTOneView from "pages/DoctorResourcesTOneView/DoctorResourcesTOneView";
import DoctorResourcesTTwoView from "pages/DoctorResourcesTTwoView/DoctorResourcesTTwoView";
import DoctorResourcesPreView from "pages/DoctorResourcesPreView/DoctorResourcesPreView";
import DoctorResourcesHCPView from "pages/DoctorResourcesHCPView/DoctorResourcesHCPView";
import DoctorResourcesChildrenAndParentsView from "pages/DoctorResourcesChildrenAndParentsView/DoctorResourcesChildrenAndParentsView";
import DoctorResearchView from "pages/DoctorResearchView/DoctorResearchView";
import DoctorHelpView from "pages/DoctorHelpView/DoctorHelpView";
import DoctorSurveyView from "pages/DoctorSurveyView/DoctorSurveyView";

import PatientHomeView from "pages/PatientHomeView/PatientHomeView";
import PatientResultsView from "pages/PatientResultsView/PatientResultsView";
import PatientNotesView from "pages/PatientNotesView/PatientNotesView";
import OneTimePatientSurveyPage from "pages/OneTimePatientSurveyPage/OneTimePatientSurveyPage";
import PatientAutoLogin from "pages/PatientAutoLogin/PatientAutoLogin";
import PatientResourcesView from "pages/PatientResourcesView/PatientResourcesView";

const getDefaultRedirectPath = (isAuthorized, accountType) => {
    if (
        isAuthorized &&
        (accountType === USER_ROLES.doctor || accountType === USER_ROLES.patient)
    ) {
        return DEFAULT_PATHS_MAP[accountType].redirectPath || "";
    }

    return `/${ROUTES.ROLE_SELECT.path}`;
};

function ProtectedRouter() {
    const isAuthorized = useSelector((state) => state.auth.isAuthorized);
    const accountType = useSelector((state) => get(state.auth, "accountType", ""));

    return (
        <Router history={HistoryService}>
            <Switch>
                <Route path={`/${ROUTES.DOCS_ROOT.path}/:path?`} exact>
                    <CleanPageLayout>
                        <Switch>
                            <Route
                                exact
                                path={`/${ROUTES.DOCS_PRIVACY_POLICY.path}/:path?`}
                                component={PrivacyPolicyPage}
                            />
                            <Route
                                exact
                                path={`/${ROUTES.DOCS_COOKIES_POLICY.path}/:path?`}
                                component={CookiesPolicyPage}
                            />
                            <Route
                                exact
                                path={`/${ROUTES.DOCS_TERMS.path}/:path?`}
                                component={TermsAndConditionsView}
                            />
                        </Switch>
                    </CleanPageLayout>
                </Route>
                <ProtectedRoute
                    exact
                    path={`/${ROUTES.TERMS_AND_CONDITIONS.path}`}
                    component={TermsAndConditionsView}
                />
                <ProtectedRoute
                    exact
                    path={`/${ROUTES.AUX_SURVEY.path}`}
                    component={AuxSurveyView}
                />
                <Route path="/:path?" exact>
                    <AuthLayout>
                        <Switch>
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.ROLE_SELECT.path}`}
                                component={RoleSelect}
                            />
                            <Route
                                exact
                                path={`/${ROUTES.SIGN_OUT.path}`}
                                component={SignOutView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.REGISTRATION_ADMIN.path}`}
                                component={SignInView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.REGISTRATION_DOCTOR.path}`}
                                component={SignInView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.REGISTRATION_PATIENT.path}`}
                                component={SignInView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.FORGOT_PASSWORD.path}`}
                                component={ForgotPasswordView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.CHANGE_PASSWORD.path}`}
                                component={ChangePasswordView}
                            />
                            <Redirect
                                from="*"
                                to={getDefaultRedirectPath(isAuthorized, accountType)}
                            />
                        </Switch>
                    </AuthLayout>
                </Route>

                <Route path={`/${ROUTES.SUPERADMIN_ROOT.path}/:path?`}>
                    <SidebarLeftLayout>
                        <Switch>
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.SUPERADMIN_RESULTS.path}`}
                                component={SuperadminResultsView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.SUPERADMIN_CLINICS_LIST.path}`}
                                component={SuperadminClinicsListView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.SUPERADMIN_CLINIC_PROFILE_ITEM.path}`}
                                component={ProfileClinicView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.SUPERADMIN_ADMINS_LIST.path}`}
                                component={SuperadminAdminsListView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.SUPERADMIN_ADMIN_PROFILE_ITEM.path}`}
                                component={ProfileAdminView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.SUPERADMIN_DOCTORS_LIST.path}`}
                                component={SuperadminDoctorsListView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.SUPERADMIN_DOCTOR_PROFILE_ITEM.path}`}
                                component={ProfileDoctorView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.SUPERADMIN_PATIENTS_LIST.path}`}
                                component={SuperadminPatientsListView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.SUPERADMIN_PATIENT_PROFILE_ITEM.path}`}
                                component={ProfilePatientView}
                            />
                            <Redirect from="*" to="/" />
                        </Switch>
                    </SidebarLeftLayout>
                </Route>

                <Route path={`/${ROUTES.ADMIN_ROOT.path}/:path?`}>
                    <SidebarLeftLayout>
                        <Switch>
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.ADMIN_DOCTORS_LIST.path}`}
                                component={AdminDoctorsListView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.ADMIN_DOCTOR_PROFILE_ITEM.path}`}
                                component={ProfileDoctorView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.ADMIN_PATIENTS_LIST.path}`}
                                component={AdminPatientsListView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.ADMIN_PATIENT_PROFILE_ITEM.path}`}
                                component={ProfilePatientView}
                            />
                            <Redirect from="*" to="/" />
                        </Switch>
                    </SidebarLeftLayout>
                </Route>

                <Route path={`/${ROUTES.DOCTOR_ROOT.path}/:path?`}>
                    <SidebarLeftLayout>
                        <Switch>
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.DOCTOR_USERS_LIST.path}`}
                                component={DoctorPatientsListView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.DOCTOR_USERS_SURVEY_RESULTS.path}`} // TODO: remove
                                component={DoctorSurveyView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.DOCTOR_USERS_SURVEY_RESULTS_ITEM.path}`}
                                component={DoctorSurveyView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.DOCTOR_RESOURCES.path}`}
                                component={DoctorResourcesView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.DOCTOR_RESOURCES_TONE.path}`}
                                component={DoctorResourcesTOneView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.DOCTOR_RESOURCES_TTWO.path}`}
                                component={DoctorResourcesTTwoView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.DOCTOR_RESOURCES_PRE.path}`}
                                component={DoctorResourcesPreView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.DOCTOR_RESOURCES_HCP.path}`}
                                component={DoctorResourcesHCPView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.DOCTOR_RESOURCES_CHILDREN_AND_PARENTS.path}`}
                                component={DoctorResourcesChildrenAndParentsView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.DOCTOR_RESEARCH.path}`}
                                component={DoctorResearchView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.DOCTOR_HELP.path}`}
                                component={DoctorHelpView}
                            />
                            <Redirect from="*" to="/" />
                        </Switch>
                    </SidebarLeftLayout>
                </Route>

                <Route path={`/${ROUTES.PATIENT_ROOT.path}/:path?`}>
                    <SidebarLeftLayout>
                        <Switch>
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.PATIENT_HOME.path}`}
                                component={PatientHomeView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.PATIENT_RESULTS.path}`}
                                component={PatientResultsView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.PATIENT_RESULTS_ITEM.path}`}
                                component={PatientResultsView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.PATIENT_NOTES.path}`}
                                component={PatientNotesView}
                            />
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.PATIENT_RESOURCES.path}`}
                                component={PatientResourcesView}
                            />
                            <Route
                                exact
                                path={`/${ROUTES.PATIENT_AUTO_LOGIN.path}`}
                                component={PatientAutoLogin}
                            />
                            <Redirect from="*" to="/" />
                        </Switch>
                    </SidebarLeftLayout>
                </Route>
                <Route path={`/${ROUTES.ONE_TIME_ROOT.path}/:path?`}>
                    <CleanPageLayout>
                        <Switch>
                            <ProtectedRoute
                                exact
                                path={`/${ROUTES.ONE_TIME_SURVEY.path}`}
                                component={OneTimePatientSurveyPage}
                            />
                            <Redirect from="*" to="/" />
                        </Switch>
                    </CleanPageLayout>
                </Route>
                <AuthLayout>
                    <Route
                        exact
                        path={`/${ROUTES.RESET_PASSWORD.path}`}
                        component={ResetPasswordView}
                    />
                </AuthLayout>
                <Redirect from="*" to="/" />
            </Switch>
        </Router>
    );
}

export default ProtectedRouter;
