import React, { useState } from 'react';
import { Button } from 'element-react';
import { useTranslation } from 'react-i18next';

import MassSignUpDialog from 'сomponents/MassSignUp/MassSignUpDialog/MassSignUpDialog';

import customComponentsStyles from 'styles/custom-components.module.scss';

function MassSignUp () {
  const { t } = useTranslation();

  const [isDialogVisible, setIsDialogVisible] = useState(false);

  return (
    <div>
      <Button
        className={customComponentsStyles.buttonCustom}
        onClick={() => {setIsDialogVisible(true);}}
      >
        {t('Auth:MassSignUp.button.massImport')}
      </Button>
      <MassSignUpDialog
        isVisible={isDialogVisible}
        setIsVisible={setIsDialogVisible}
      />
    </div>
  );
}

export default MassSignUp;
