import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { ROUTES } from "constants/routes";
import { PATIENT_LIST_PAGE_SIZE, PAGE_SIZE } from "constants/pagination";
import { datesFormat } from "constants/datesAndTime";

import HistoryService from "services/history/HistoryService";
import * as ApiService from "services/api/ApiService";

import * as patientsActions from "store/patients/actions";

import ListTable from "componentsShared/ListTable/ListTable";
import Loader from "componentsShared/Loader/Loader";

ListTablePatientsDoctorCabinet.propTypes = {
    localSearch: PropTypes.string.isRequired,
};

function ListTablePatientsDoctorCabinet({ localSearch }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { data, isLoadingGetPatientsList } = useSelector((state) => state?.patients) ?? {};
    const dataSequence = useSelector((state) => state?.patients?.dataSequence) ?? [];
    const totalElements = useSelector((state) => state?.patients?.meta?.totalElements) || 0;
    const surveysListData = useSelector((state) =>
        Object.values(state.surveys.surveysList.data)
    );

    const [isLoading, setLoading] = useState(false);
    const [patientsList, setPatientsList] = useState([]);

    const columns = [
        {
            label: t("Patients:ListTablePatientsDoctorCabinet.table.column.label.fullName"),
            prop: "name",
            sortable: true,
        },
        {
            label: t(
                "Patients:ListTablePatientsDoctorCabinet.table.column.label.diabetesType"
            ),
            prop: "diabetesType",
            sortable: true,
        },
        {
            label: t(
                "Patients:ListTablePatientsDoctorCabinet.table.column.label.lastCompleted"
            ),
            prop: "lastCompleted",
            sortable: true,
        },
        {
            label: t(
                "Patients:ListTablePatientsDoctorCabinet.table.column.label.patientConcern"
            ),
            prop: "patientConcern",
            sortable: true,
        },
        {
            label: t("Patients:ListTablePatientsDoctorCabinet.table.column.label.riskScore"),
            prop: "riskScore",
            sortable: true,
        },
        {
            label: t(
                "Patients:ListTablePatientsDoctorCabinet.table.column.label.priorityCode"
            ),
            prop: "priorityCode",
            sortable: true,
        },
    ];

    const renderBullet = (score) => {
        let backgroundColor = "red";

        if (score >= 1 && score <= 3) {
            backgroundColor = "green";
        } else if (score >= 4 && score <= 8) {
            backgroundColor = "#ff9900";
        }

        return (
            <div
                style={{
                    width: 20,
                    height: 20,
                    backgroundColor,
                    borderRadius: 20,
                }}
            ></div>
        );
    };

    const scoresPromise = async (email) => {
        const { content } = await ApiService.surveyInstancesListGet(encodeURIComponent(email));

        const surveyWithScoreId = content.find(({ status }) => status !== "CREATED")?.id;

        if (surveyWithScoreId) {
            const data = await ApiService.surveyInstanceGet(surveyWithScoreId);

            if (data.scores.length > 0) {
                const chosenScore = data.scores
                    .sort((a, b) => a.scoreValue - b.scoreValue)
                    .slice(-1)[0];

                const foundDiabetesTypeName = surveysListData?.find(
                    (type) => type.diabetesType === data.survey.diabetesType
                )?.name;

                return {
                    email,
                    diabetesType: foundDiabetesTypeName,
                    lastCompleted: data.lastUpdatedTimestamp
                        ? moment(data.lastUpdatedTimestamp).format(datesFormat)
                        : "",
                    patientConcern: t(
                        `Patients:ListTablePatientsDoctorCabinet.table.value.${chosenScore.category}`
                    ),
                    riskScore: chosenScore.scoreValue,
                    priorityCode: renderBullet(chosenScore.scoreValue),
                };
            }
        }
    };

    const [requestsCalled, setRequestsCalled] = useState(false);

    useEffect(async () => {
        if (
            patientsList.length === 0 &&
            dataSequence.length > 0 &&
            surveysListData.length > 0 &&
            !requestsCalled
        ) {
            setRequestsCalled(true);
            const promises = [];
            const patientListProcessed = dataSequence.map((key) => {
                const { firstName, lastName, email } = data[key];

                promises.push(scoresPromise(email));

                return {
                    name: `${firstName} ${lastName}`,
                    email,
                };
            });
            setLoading(true);
            const responses = await Promise.all(promises);
            setLoading(false);

            setPatientsList(
                patientListProcessed.map((patient) => {
                    const additionalData = responses
                        .filter((response) => response)
                        .find(({ email }) => patient.email === email);
                    if (additionalData) {
                        return {
                            ...patient,
                            ...additionalData,
                        };
                    }
                    return patient;
                })
            );

            return () => {
                setRequestsCalled(false);
            };
        }
    }, [data, dataSequence, surveysListData]);

    function onTableRowClick(rowData) {
        dispatch(patientsActions.setSelectedPatient({ email: rowData.email }));
        HistoryService.push(`/${ROUTES.DOCTOR_USERS_SURVEY_RESULTS.path}`);
    }

    const onPaginationChange = (page) => {
        dispatch(
            patientsActions.setPatientsListFetchParams.pagination({
                params: { size: PAGE_SIZE, page: page - 1 },
            })
        );
    };

    if (isLoadingGetPatientsList || isLoading) {
        return (
            <div style={{ position: "relative", marginTop: "25%" }}>
                <Loader isVisible />
            </div>
        );
    }

    const filteredPatientsList = patientsList.filter(({ name }) => {
        if (localSearch.length === 0) {
            return true;
        }

        const words = localSearch
            .trim()
            .split(" ")
            .map((word) => word.toLocaleLowerCase());

        return (
            words.filter((word) => name.toLocaleLowerCase().includes(word)).length ===
            words.length
        );
    });
    return (
        <ListTable
            columns={columns}
            onTableRowClick={onTableRowClick}
            tableData={filteredPatientsList}
            withPagination={totalElements > PATIENT_LIST_PAGE_SIZE}
            totalElements={totalElements}
            onPaginationChange={onPaginationChange}
            isAnimated
            isWithoutBorders
        />
    );
}

export default ListTablePatientsDoctorCabinet;
