import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PAGE_SIZE } from 'constants/pagination';
import { ROUTES } from 'constants/routes';

import * as patientsActions from 'store/patients/actions';

import HistoryService from 'services/history/HistoryService';

import ListTable from 'componentsShared/ListTable/ListTable';

function ListTablePatients () {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const data = useSelector(state => state?.patients?.data) ?? {};
  const dataSequence = useSelector(state => state?.patients?.dataSequence) ?? [];
  const totalElements = useSelector(state => state?.patients?.meta?.totalElements) ?? 0;

  const [patientsList, setPatientsList] = useState([]);
  
  useEffect(() => {
    dispatch(patientsActions.getPatientsList.start());
  }, []);

  useEffect(() => {
    const patientListProcessed = dataSequence.map((key) => {
      const item = data[key];
      return {
        name: `${item.firstName} ${item.lastName}`,
        email: item.email,
      };
    });

    setPatientsList(patientListProcessed);
  }, [data, dataSequence]);

  const columns = [
    {
      label: t('Patients:ListTablePatients.table.column.label.fullName'),
      prop: 'name',
      sortable: true,
    },
  ];

  function onTableRowClick(rowData) {
    HistoryService.push(`/${ROUTES.ADMIN_PATIENT_PROFILE.path}/${rowData.email}`);
  }

  const onPaginationChange = (page) => {
    dispatch(patientsActions.setPatientsListFetchParams.pagination({params: {size: PAGE_SIZE, page: page - 1}}));
  };

  return (
    <ListTable
      columns={columns}
      onTableRowClick={onTableRowClick}
      tableData={patientsList}
      withPagination={true}
      totalElements={totalElements}
      onPaginationChange={onPaginationChange}
      isWithoutBorders
    />
  );
}

export default ListTablePatients;
